//Variables
$body-bg: #0e0e0e;
$body-text: #fff;
$border-radius-base: 4px;
$border-radius-lg: 4px;
$btn-border-radius-base: $border-radius-base;
$font: 'Poppins', sans-serif;

$input-color-placeholder: #afafaf;
//$blue: #4383ED;
$blue: #01b9ff;
$blue-light: #97baf5;
//$yellow: #F2BF27;
$yellow: #ffc961;
//$orange: #F07825;
$orange: #ff5a07;
$darkred: #c1322d;
//$red: #FF4081;
$red: #fd0700;
$red2: #fd0700;
//$purple: #9462EA;
$purple: #662dd3;
//$green: #0FA538;
$green: #2ed283;
$black: $body-bg;
$white: #fff;

$gray-border: #e0e0e0;
$gray-light: #d0d0d0;
$gray: #a7a7a7;
$gray-disabled: #cdcdd2;
$gray-dark: #282a2c;
$rainbow: linear-gradient(
  90deg,
  rgba(255, 182, 42, 1) 0%,
  rgba(255, 69, 0, 1) 16%,
  rgba(255, 0, 108, 1) 36%,
  rgba(102, 45, 211, 1) 64%,
  rgba(64, 153, 254, 1) 84%,
  rgba(46, 210, 131, 1) 100%
);
$rainbow45: linear-gradient(45deg, #f5b90a 0%, #ec473a 34%, #4383ed 69%, #0fa538 100%);

$brand-primary: $blue;

$box-shadow: 0px 3px 10px rgba(167, 167, 167, 0.29);
$header-height: 80px;

//Responsive Utilities
$screen-sm-min: 576px; // Small tablets and large smartphones
$screen-md-min: 768px; // Small tablets
$screen-lg-min: 992px; // Tablets / small desktops
$screen-xl-min: 1200px; // Large desktops

//Media Query Mixins
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}
