@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;900&display=swap");

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

$lightBgText: #9096a8;
$lightFgText: #b6bac5;
$progressBg: #e4e7ef;
$blue: #5282f6;
$blue-light: #e3ebff;
$red: #fc5e87;
$red-light: #fff4f7;
$yellow: #ffc000;
$yellow-light: #fffbef;
$green: #00d98b;
$green-light: #dcfff3;
$primary: $blue;
$secondary: #9096a8;
$success: $green;
$info: $blue-light;
$warning: $yellow;
$danger: $red;

$body-bg: #f6f6fb;
$body-text: #222e4e;
$border-radius-base: 4px;
$border-radius-lg: 4px;
$btn-border-radius-base: $border-radius-base;
$font: "Mulish", sans-serif;
$font-family-sans-serif: "Mulish", sans-serif;
$box-shadow: 0px 3px 10px rgba(167, 167, 167, 0.29);
$header-height: 8rem;
$container-max-widths: (
  md: 960px,
  lg: 1100px,
  xl: 1290px,
);
$screen-sm-min: 720px; // Small tablets and large smartphones
$screen-md-min: 960px; // Small tablets
$screen-lg-min: 1200px; // Tablets / small desktops
$screen-xl-min: 1360px; // Large desktops

@import "~bootstrap/scss/bootstrap.scss";

html,
body {
  font-size: 62.5%;
  height: 100%;
  font-family: "Mulish", sans-serif;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3.2rem;
}
h3 {
  font-size: 2.8rem;
}
h4 {
  font-size: 2.4rem;
}
h5 {
  font-size: 2rem;
}
h6 {
  font-size: 1.6rem;
}
div,
span,
p,
a,
button,
.btn {
  font-size: 1.6rem;
}

#root,
.app-container {
  height: 100%;
}

.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
