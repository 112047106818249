@import 'assets/scss/variables.scss';
$gray-bg: #191919;
$gray-lighter: #212121;
$gray-darker: #141414;
$component-bg-light: white;
$component-bg-light-active: #efefef;
$component-active-bg: $black; //#EFEFEF;
$component-text: $gray-dark;
$component-text-light: #6f6f6f;
$component-active-text: white;
$component-border: $gray-light;
$component-border: #363636;
$component-transition: 0.3s all;

.month-wrap {
  width: 100%;
  .month-label {
    text-align: center;
    margin: 0 0 1rem;
    background: $red2;
    color: white;

    label {
      font-weight: 700;
      font-size: 26px;
      text-transform: uppercase;
      padding: 1rem;
    }
  }
  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    width: 90%;
    margin: 3rem auto 1rem;

    .day-label {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    justify-items: center;
    width: 90%;
    margin: 1rem auto 3rem;

    button {
      color: black;
      font-weight: 300;
      font-size: 20px;
      background: transparent;
      border: none;
      padding: 15px;

      span {
        display: flex;
        width: 65px;
        height: 65px;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        transition: 0.3s all;
        &.selected {
          border: 1px solid $gray-border;
          border-top: 19px solid $red2;
          color: $gray-dark;
          font-weight: 700;
          box-shadow: $box-shadow;
          font-size: 23px;
        }
      }

      &:hover {
        span {
          background: lighten($red2, 20%);
          color: $red2;
        }
      }

      &:disabled {
        color: $gray-disabled;
      }
    }
  }
}

.calendar-component-wrap {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  height: auto;
  background-color: #fff;
  border-radius: 4px;
  .calendar-wrap,
  .calendar-card {
  }
  .calendar-close {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 22px;
    cursor: pointer;
    transition: 0.3s all;
    &:hover {
      opacity: 0.5;
    }
  }
  .calendar-card {
    position: relative;
    padding-top: 2rem;
    background: #fff;
    border: 1px solid #e4e7ef;
    box-shadow: 0 5px 15px -5px rgba(96, 53, 154, 0.25);
    color: #000;
    border-radius: 4px;
    .calendar-wrap {
      width: 90%;
      .month-toggle {
        top: 0;
        color: #5282f6;
      }
    }
  }
  .month-wrap {
    width: 100%;
    .month-label {
      text-align: center;
      margin: 0 0 1rem;
      background: transparent;
      color: $component-text-light;

      label {
        font-weight: 700;
        font-size: 1rem;
        color: #000;
        padding: 0rem;
      }
    }
    .weekdays {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      justify-content: center;
      width: 100%;
      margin: 0 auto; //1rem auto 1rem;

      .day-label {
        font-weight: bold;
        font-size: 1rem;
        color: #000;
        text-transform: uppercase;
      }
    }
    .days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      justify-content: center;
      justify-items: center;
      width: 100%;
      margin: 0.5rem auto 0rem;

      button {
        color: #8f95a7;
        font-weight: 300;
        font-size: 1rem;
        background: transparent;
        border: none;
        padding: 0px;
        cursor: pointer;

        span {
          display: flex;
          width: 25px;
          height: 25px;
          font-size: 1rem;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
          transition: 0.3s all;
          &.selected {
            border: none;
            border-top: none;
            color: #487ae8;
            font-weight: 700;
            box-shadow: none;
            font-size: 1rem;
            background: #e4e4f1;
          }
        }

        &:hover {
          span {
            background: $blue;
            color: $component-active-text;
          }
        }

        &:disabled {
          color: $component-text-light;
        }
      }
    }
  }
}
