@import '../theme/index.scss';
.upload-component-wrap {
  background: transparent;
  color: $body-text;
  .upload-component {
    font-size: 12px;
    cursor: pointer;
    img {
      width: 65px;
      height: auto;
    }
    p {
      margin: 0;
    }
    u {
      color: $blue;
    }
  }
}
