@import 'src/assets/scss/variables.scss';

$color__back: #252224;
$color__back--high: lighten($color__back, 20%);
$color__text: #fff;
$color__text--low: darken($color__text, 50%);
$color__primary: $blue;

$checkbox__size: 20px;
$switch__size: $checkbox__size;

$checkbox__border__size: 1px;
$checkbox__border__color: #a5b4b4;
$checkbox__border__color--disabled: $color__back--high;

$checkbox__checkmark__size: $checkbox__size - (2 * $checkbox__border__size);
$checkbox__checkmark__size--indeterminate: 2px;
$checkbox__checkmark__color: $color__primary;
$checkbox__checkmark__color--disabled: $color__back--high;

$checkbox__label__color: $color__text;
$checkbox__label__color--disabled: $color__text--low;

// CHECKBOXES

.m-checkbox {
  display: flex;
  align-items: center;

  &__label {
    flex-shrink: 0;
    padding: 0.5rem 1rem;
    color: $checkbox__label__color;
    cursor: pointer;
  }

  &__input {
    position: relative;
    flex-shrink: 0;
    width: $checkbox__size;
    height: $checkbox__size;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none; // TODO: manage :focus
    background: transparent;
    cursor: pointer;
    border: $checkbox__border__size solid $checkbox__border__color;
    border-radius: 3px;

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      transition: all 0.1s;
      background: no-repeat
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='8'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-429.5-126h1215v740h-1215z'/%3E%3Cpath d='M4.604 15.113a.032.032 0 11.013.037l-2.25-2.006a.22.22 0 01.327 0l1.877 1.69.314.283s0 0 0 0l.198.178 6.606-8.239a.22.22 0 11-.275 0l-5.522 6.451-1.288 1.606z' stroke='%23FFF' stroke-width='.3' fill='%23FFF' fill-rule='nonzero'/%3E%3Cpath stroke='%23FFF' stroke-width='.3' d='M0 5.77l2.354 2.046L8.814 0'/%3E%3C/g%3E%3C/svg%3E");
      background-size: contain;
      border-radius: 2px;
    }

    &:checked,
    &:indeterminate {
      &::before {
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
      }
    }

    &:indeterminate {
      &::before {
        top: ($checkbox__checkmark__size / 2) - ($checkbox__checkmark__size--indeterminate / 2);
        bottom: ($checkbox__checkmark__size / 2) - ($checkbox__checkmark__size--indeterminate / 2);
      }
    }

    &:disabled {
      border-color: $checkbox__border__color--disabled;
      cursor: default;

      &::before {
        background-color: $checkbox__checkmark__color--disabled;
      }

      + .m-checkbox__label {
        color: $checkbox__label__color--disabled;
        cursor: default;
      }
    }
  }

  &--has-error {
    &__input {
      border-color: red;
      background-color: rgba(red, 0.2);

      &::before {
        background-color: red;
      }
    }
  }

  &--switch {
    &__label {
      &::after {
        content: 'off';
        margin-left: 0.25rem;
      }
    }

    &__input {
      width: (2 * $switch__size) - (2 * $checkbox__border__size);
      height: $switch__size;
      border: $checkbox__border__size solid $checkbox__border__color--disabled;
      border-radius: $checkbox__checkmark__size;

      &::before {
        top: $checkbox__border__size;
        right: $switch__size - $checkbox__border__size;
        bottom: $checkbox__border__size;
        left: $checkbox__border__size;
        border-radius: 50%;
        background: $checkbox__checkmark__color--disabled;
      }

      &:checked {
        border-color: $checkbox__border__color;

        + .m-checkbox--switch__label {
          &::after {
            content: 'on';
          }
        }

        &::before {
          right: $checkbox__border__size;
          left: $switch__size - $checkbox__border__size;
          background: $checkbox__border__color;
        }
      }
    }
  }
}
